import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content, { HTMLContent } from '../components/Content'
import { FaCalendarAlt, FaUser } from "react-icons/fa"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import 'gatsby-plugin-breadcrumb/gatsby-plugin-breadcrumb.css'

const _ = require('lodash')

export const BlogPostTemplate = ({
  crumbs,
  content,
  contentComponent,
  description,
  tags,
  title,
  date,
  author,
  category,
  posts,
  article_section,
  location,
}) => {

  const disableLinks = [location.pathname]
  const PostContent = contentComponent || Content

  let categories = []
  posts.forEach(edge => {
    if (_.get(edge, `node.frontmatter.category`)) {
      categories = categories.concat(edge.node.frontmatter.category)
    }
  })
  categories = _.uniq(categories)

  let archives = []
  posts.forEach(edge => {
    if (_.get(edge, `node.frontmatter.date`)) {
      archives = archives.concat(edge.node.frontmatter.date.substring(3))
    }
  })
  archives = _.uniq(archives)

  return (
    <section className="section" style={{ marginTop: 100 }}>
      <SEO title={title} description={description} article_section={article_section} blogservice={true} blogonly={true} />
      <div className="container">
        <div className="breadcrumbs">
          <Breadcrumb
            crumbs={crumbs}
            crumbLabel={title}
            disableLinks={disableLinks}
          />
        </div>
        <div className="columns">
          <div className="column is-9">
            <h1 className="title is-size-4 has-text-weight-bold is-bold-light" style={{ marginBottom: 0 }}>
              {title}
            </h1>
            <p className="post-date">
              <FaCalendarAlt />
              <span>
                {date}
              </span>
              &nbsp;&nbsp;
              <FaUser />
              <span>
                By {author}
              </span>
              &nbsp;&nbsp;
              <FaCalendarAlt />
              <span>
                In {category}
              </span>
            </p>
            <PostContent content={content} className="markdown" />
          </div>
          <div className="column is-3">
            <h4>CATEGORIES</h4>
            <div>
              {
                categories.map((item, i) => {
                  return (
                    <div className="category">
                      <a href={`/categories/${kebabCase(item)}/`}>
                        {item}
                      </a>
                    </div>
                  )
                })
              }
            </div>
            <h4 style={{ margin: "2rem 0 0" }}>META</h4>
            <div className="category">
              <a href="/admin">Login</a>
            </div>
            <h4 style={{ margin: "2rem 0 0" }}>ARCHIVES</h4>
            <div>
              {
                archives.map((item, i) => {
                  return (
                    <div className="category">
                      <a href={`/archives/${kebabCase(item)}/`}>
                        {item}
                      </a>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  category: PropTypes.string,
  posts: PropTypes.string,
  article_section: PropTypes.string,
  location: PropTypes.object,
}

const BlogPost = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
  location,
  data
}) => {
  const { markdownRemark: post } = data
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <BlogPostTemplate
        crumbs={crumbs}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        meta_title={post.frontmatter.title}
        date={post.frontmatter.date}
        author={post.frontmatter.author}
        category={post.frontmatter.category}
        posts={posts}
        article_section={post.frontmatter.article_section}
        location={location}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
        category
        description
        article_section
      }
    }
    allMarkdownRemark(
      sort: {order: DESC, fields: [frontmatter___date]},
      filter: {frontmatter: {templateKey: {eq: "blog-post"}}}
    ){
      edges{
        node{
          id
          fields{
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "DD MMMM YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            author
            category
          }
        }
      }
    }
  }
`
